import React, { Component } from 'react';
import './App.css';


export default class DividerThin extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elRectangle = {
        background: 'rgba(41, 29, 16, 0.000)',
     };
    const style_elRectangle2 = {
        background: 'rgba(0, 0, 0, 1.000)',
        opacity: 0.25,
     };
    
    return (
      <div className="DividerThin" style={baseStyle}>
        <div className="layoutFlow">
          <div className='elRectangle'>
            <div className='' style={style_elRectangle} />
          
          </div>
          
        </div>
        <div className="foreground">
          <div className='elRectangle2' style={style_elRectangle2} />
        </div>
      </div>
    )
  }
  

}
