import React, { Component } from 'react';
import './App.css';
import Logo from './Logo';
import AppStore from './AppStore';


export default class TopWide extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    
    return (
      <div className="TopWide" style={baseStyle}>
        <div className="layoutFlow">
          <div className='hasNestedComps elColumns'>
            <div className=''>
              <div className="col0">
                <Logo appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
              <div className="col1">
                <AppStore appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
            </div>
          
          </div>
          
        </div>
      </div>
    )
  }
  

}
