import React, { Component } from 'react';
import './App.css';
import img_elApp_Store from './images/AppStoreCentered_elApp_Store_0.png';


export default class AppStoreCentered extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elApp_Store = {
        height: 'auto',
        display: 'none',
     };
    const style_elTextBlock = {
        fontSize: 23.8,
        color: 'rgba(254, 255, 254, 0.8500)',
        textAlign: 'center',
     };
    
    return (
      <div className="AppStoreCentered" style={baseStyle}>
        <div className="foreground">
          <img className='elApp_Store' style={style_elApp_Store} src={img_elApp_Store} alt=""  />
          <div className='font-laoSangamMN  elTextBlock' style={style_elTextBlock}>
            <div>{this.props.locStrings.appstorecentered_textblock_104744}</div>
          </div>
        </div>
      </div>
    )
  }
  

}
