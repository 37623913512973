import React, { Component } from 'react';
import './App.css';


export default class AppStore extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elTextBlock = {
        fontSize: 23.8,
        color: 'rgba(254, 255, 254, 0.8500)',
        textAlign: 'right',
     };
    const style_elTextBlockCopy = {
        fontSize: 19.0,
        color: 'rgba(255, 255, 255, 0.4507)',
        textAlign: 'right',
     };
    
    return (
      <div className="AppStore" style={baseStyle}>
        <div className="foreground">
          <div className='font-laoSangamMN  elTextBlock' style={style_elTextBlock}>
            <div>{this.props.locStrings.appstore_textblock_271585}</div>
          </div>
          <div className='font-laoSangamMN  elTextBlockCopy' style={style_elTextBlockCopy}>
            <div>{this.props.locStrings.appstore_textblock_815703}</div>
          </div>
        </div>
      </div>
    )
  }
  

}
