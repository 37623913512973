import React, { Component } from 'react';
import './App.css';
import img_elBack from './images/StartScreen_elBack_38040.jpg';
import BottomBar from './BottomBar';
import TopNarrow from './TopNarrow';
import TopWide from './TopWide';
import DividerThin from './DividerThin';


export default class StartScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
        width: '100%',
        height: '100%',
     };
    const style_elBackground_outer = {
        backgroundColor: '#f6f6f6',
     };
    const style_elBack = {
        backgroundImage: 'url('+img_elBack+')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
     };
    
    let contentElement_elSwapper;  // This element's content can vary based on screen size
    contentElement_elSwapper = (<TopNarrow appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    if (this.props.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapper = (<TopNarrow appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapper = (<TopNarrow appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapper = (<TopWide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapper = (<TopWide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    
    
    return (
      <div className="AppScreen StartScreen" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackground' style={style_elBackground_outer}>
            <div className='appBg' style={style_elBackground} />
          
          </div>
          
          <div className='containerMinHeight elBack' style={style_elBack} />
          <div className='hasNestedComps elComp'>
            <BottomBar ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className='hasNestedComps elSwapper'>
            <div className=''>
              {contentElement_elSwapper}
            </div>
          
          </div>
          
          <div className='hasNestedComps elDividerThin'>
            <div className=''>
              <DividerThin ref={(el)=> this._elDividerThin = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          
          </div>
          
        </div>
      </div>
    )
  }
  

}
