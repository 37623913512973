import React, { Component } from 'react';
import './App.css';


export default class BottomBar extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elRectangle = {
        background: 'rgba(0, 0, 0, 0.134)',
     };
    const style_elTextBlock = {
        color: 'rgba(254, 255, 254, 0.8500)',
        textAlign: 'right',
     };
    
    return (
      <div className="BottomBar" style={baseStyle}>
        <div className="layoutFlow">
          <div className='elRectangle'>
            <div className='' style={style_elRectangle} />
          
          </div>
          
        </div>
        <div className="foreground">
          <div className='baseFont elTextBlock' style={style_elTextBlock}>
            <div>{this.props.locStrings.bottombar_textblock_337552}</div>
          </div>
        </div>
      </div>
    )
  }
  

}
