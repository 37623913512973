import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_475753";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textblock_526771";
    item['en'] = "Help find yours or others pets!\nmake families whole again!";
    
    item = {};
    this.items.push(item);
    item['key'] = "text1_textblock_426771";
    item['en'] = "Help find yours or others pets!\nmake families whole again!";
    
    item = {};
    this.items.push(item);
    item['key'] = "logo_textblock_541929";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "logocentered_textblock_507875";
    item['en'] = "Moosia";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_1002344";
    item['en'] = "Losing a pet can be very distressing for both the pet and owner. \nThe first 24hrs are crucial. Just by installng this app you can make a difference...";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text2_622889";
    item['en'] = "Help reunite missing pets ";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text3_863075";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_textblock_514986";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textblock_613103";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "appstorecentered_textblock_104744";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "appstore_textblock_271585";
    item['en'] = "Moosia ";
    
    item = {};
    this.items.push(item);
    item['key'] = "bottombar_textblock_337552";
    item['en'] = "© Moosia LLC";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_445407";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_66135";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_158373";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "appstore_textblock_815703";
    item['en'] = "Software Development";
    
    item = {};
    this.items.push(item);
    item['key'] = "logocentered_textblock_560648";
    item['en'] = "Moosia ";
    
    item = {};
    this.items.push(item);
    item['key'] = "logocentered_textblockcopy_278582";
    item['en'] = "Software Development";
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
