import React, { Component } from 'react';
import './App.css';
import img_elLogoWhiteFur2 from './images/Logo_elLogoWhiteFur2_196826.png';


export default class LogoCentered extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elLogoWhiteFur2 = {
        height: 'auto',
     };
    const style_elTextBlock = {
        fontSize: 23.8,
        color: 'rgba(254, 255, 254, 0.8500)',
        textAlign: 'center',
     };
    const style_elTextBlockCopy = {
        fontSize: 19.0,
        color: 'rgba(255, 255, 255, 0.4507)',
        textAlign: 'center',
     };
    
    return (
      <div className="LogoCentered" style={baseStyle}>
        <div className="foreground">
          <img className='elLogoWhiteFur2' style={style_elLogoWhiteFur2} src={img_elLogoWhiteFur2} alt=""  />
          <div className='font-laoSangamMN  elTextBlock' style={style_elTextBlock}>
            <div>{this.props.locStrings.logocentered_textblock_560648}</div>
          </div>
          <div className='font-laoSangamMN  elTextBlockCopy' style={style_elTextBlockCopy}>
            <div>{this.props.locStrings.logocentered_textblockcopy_278582}</div>
          </div>
        </div>
      </div>
    )
  }
  

}
